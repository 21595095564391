import {useLocalStorage} from '@/hooks/localStorage';
import {createContext, useContext} from 'react';

const AlertContext = createContext();

export const useAlertContext = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({children}) => {
  const [alertMessage, setAlertMessage] = useLocalStorage('alertMessage', '');

  return (
    <AlertContext.Provider
      value={{
        alertMessage,
        setAlertMessage,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};
