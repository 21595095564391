import React from 'react';

import {useSession} from 'next-auth/react';
import {useRouter} from 'next/router';
import {Loading} from '../components/generic/Loading';
import {PERMISSION_GROUP_MAP} from './permissions';

export function AuthPageGate({children, permissions, isPublic}) {
  const router = useRouter();
  const {hasPermission, user, status} = usePermissions(permissions);
  const isLogin = router.pathname.startsWith('/auth');

  React.useEffect(() => {
    if (status === 'loading' || isPublic) return;

    if (isLogin && user) {
      router.push(router.query?.callbackUrl ?? '/');
      return;
    }
    if (!user && !isLogin) {
      router.push(
        '/auth/login?error=SessionTimeout&callbackUrl=' +
          (router.pathname ? encodeURI(router.pathname) : '/')
      );
      return;
    }
    if (!hasPermission) {
      router.push('/');
      return;
    }
    /* eslint-disable-next-line */
  }, [status, hasPermission, user, isPublic, router]);

  if (isPublic || isLogin) return children;
  if (status != 'authenticated') return <Loading />;

  if (hasPermission) return children;
}

export function AuthGate({children, permissions}) {
  const {hasPermission, status} = usePermissions(permissions);

  if (status == 'loading') return children;

  if (hasPermission) return children;
  return null;
}

export function usePermissions(permissions) {
  const {data: session, status} = useSession();
  if (status == 'loading')
    return {
      hasPermission: false,
      user: null,
      status: status,
    };

  const user = session?.user;

  return {
    hasPermission: userHasPermission(user, permissions),
    user: user,
    status: status,
  };
}

export function userHasPermission(user, requiredPermissions) {
  if (!requiredPermissions) return true;

  if (!Array.isArray(requiredPermissions))
    requiredPermissions = [requiredPermissions];

  if (requiredPermissions.length == 0) return true;

  if (!user?.role) return false;

  const permissions_data = PERMISSION_GROUP_MAP;

  let hasPermission = true;
  for (const requiredPermission of requiredPermissions) {
    if (
      requiredPermission &&
      !permissions_data[requiredPermission].includes(user.role)
    ) {
      hasPermission = false;
    }
  }

  return hasPermission;
}

export function userIsStaff(user) {
  return user?.is_admin == true;
}
