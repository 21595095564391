import {useLocalStorage} from '@/hooks/localStorage';
import {createContext, useContext} from 'react';

const SidebarContext = createContext();

export function useSidebar() {
  return useContext(SidebarContext);
}

export function SidebarProvider({children}) {
  const [open, setOpen] = useLocalStorage('isSidebarOpen', false);

  const toggleSideBar = () => {
    setOpen(!open);
  };

  return (
    <SidebarContext.Provider value={{open, toggleSideBar}}>
      {children}
    </SidebarContext.Provider>
  );
}
