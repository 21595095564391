import {useEffect, useState} from 'react';

function useLocalStorage(key, initialValue) {
  const isClient = typeof window !== 'undefined';
  const [value, setValue] = useState(() => {
    const storedValue = isClient ? localStorage.getItem(key) : null;
    const hasStoredValue = storedValue && storedValue != 'undefined';
    return hasStoredValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    if (isClient) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value, isClient]);

  return [value, setValue];
}

export {useLocalStorage};
