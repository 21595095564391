import {handleSubmitError} from '@/components/notification/defaults';
import {create, keyResolver, windowScheduler} from '@yornaath/batshit';
import axios from 'axios';
import Cookies from 'cookies';
import {
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query';

//queryFunction é OPCIONAL
export function useGetRequest(paramsArr, queryFunction, options = {}) {
  if (!queryFunction) {
    queryFunction = buildBatchedGetRequest(paramsArr, {});
  }

  return useQuery(paramsArr, queryFunction, options);
}

//queryParams é OBRIGATÓRIO e deve ser consistente com o queryParam das queries em array. Exemplo:
//  CommentArray => ["posts", 1, "comments"]
//  Comment => ["posts", 1, "comments", 1]

//queryTransformFunction é OPCIONAL. Ele modifica o valor visto pelo hook para os elementos. Não altera o que é armazenado no cache
// Assinatura esperada: (element) => tranformedElement

//sideEffectFunction é OPCIONAL. Ele é chamado com o valor retornado pela request, e deve retornar um array de sideEffects
// Assinatura esperada: (element) => [sideEffect1, sideEffect2, ...]
// O sideEffect é um objeto com os seguintes campos:
//  queryKey: Array com o queryKey da query que será atualizada
//  data: Novo valor da query

// IMPORTANTE: os side effects NÃO PODEM ALTERAR queries dentro da mesma queryKey fornecida para função, pois isso gera loop infinito
// CASO DE USO: A request do backend retorna dados de um objeto relacionado, e queremos atualizar o cache desse objeto relacionado. Ex:
// Comment: {id: 1, post: 1, content: "blablabla", author: {id: 1, name: "João"}}
// sideEffectFunction: (comment) => [{queryKey: ["users", comment.author.id], data: comment.author}]

export function useCustomGetRequest(
  queryKey,
  queryFunction,
  queryTransformFunction,
  sideEffectFunction,
  options = {}
) {
  if (!queryFunction) {
    queryFunction = buildBatchedGetRequest(queryKey, {});
  }
  const queryClient = useQueryClient();
  return useQuery(queryKey, queryFunction, {
    ...options,
    onSuccess: (data) => {
      if (sideEffectFunction) {
        const sideEffects = sideEffectFunction(data);
        sideEffects.forEach((sideEffect) => {
          queryClient.setQueryData(sideEffect.queryKey, sideEffect.data);
        });
      }
    },
    select: (data) => {
      if (queryTransformFunction) {
        return queryTransformFunction(data);
      }
      return data;
    },
  });
}

//queryFunction é OPCIONAL
export function useGetArrayRequest(paramsArr, queryFunction, options) {
  return useCustomGetArrayRequest(
    paramsArr,
    {},
    queryFunction,
    null,
    null,
    null,
    options
  );
}

//queryKey é OBRIGATÓRIO e deve ser consistente com o queryParam das queries individuais. Exemplo:
//  CommentArray => ["posts", 1, "comments"]
//  Comment => ["posts", 1, "comments", 1]

//queryParams é OPCIONAL. Caso fornecido (por exemplo, para paginação, filtrar, etc), ele será passado somente para a query do array, e não para as queries individuais

//paramsArr é OPCIONAL. Se não estiver presente, será igual a queryKey

//queryTransformFunction é OPCIONAL. Ele modifica o valor visto pelo hook para TODOS os elementos. Não altera o que é armazenado no cache
// Assinatura esperada: (arrayElement) => tranformedArrayElement

//sideEffectFunction é OPCIONAL. Ele é chamado com o valor de cada elemento do array, e deve retornar um array de sideEffects
// Assinatura esperada: (arrayElement) => [sideEffect1, sideEffect2, ...]
// O sideEffect é um objeto com os seguintes campos:
//  queryKey: Array com o queryKey da query que será atualizada
//  data: Novo valor da query

// IMPORTANTE: os side effects NÃO PODEM ALTERAR queries dentro da mesma queryKey fornecida para função, pois isso gera loop infinito
// CASO DE USO: A request do backend retorna dados de um objeto relacionado, e queremos atualizar o cache desse objeto relacionado. Ex:
// Comment: {id: 1, post: 1, content: "blablabla", author: {id: 1, name: "João"}}
// sideEffectFunction: (comment) => [{queryKey: ["users", comment.author.id], data: comment.author}]

export function useCustomGetArrayRequest(
  queryKey,
  queryParams,
  paramsArr,
  queryTransformFunction,
  sideEffectFunction,
  batched = true,
  options = {},
  queryFunction
) {
  if (!paramsArr) {
    paramsArr = queryKey;
  }
  if (!queryFunction) {
    queryFunction = buildGetRequest(paramsArr, queryParams);
  }

  const queryClient = useQueryClient();
  const {data, isLoading, isError, isSuccess} = useQuery(
    [...queryKey, queryParams],
    queryFunction,
    {
      ...options,
      staleTime: Infinity,
      select: (data) => {
        if (queryTransformFunction) {
          return data.results.map((elem) => queryTransformFunction(elem));
        }
        return data;
      },
      onSuccess: (data) => {
        if (sideEffectFunction) {
          data.forEach((arrayObj) => {
            sideEffectFunction(arrayObj).forEach((sideEffects) => {
              sideEffects.forEach((sideEffect) => {
                queryClient.getQueryData(sideEffect.queryKey, sideEffect.data);
              });
            });
          });
        }
      },
    }
  );

  const queries = useQueries(
    createItemsQueries(paramsArr, data?.results || data, {
      batched,
      queryTransformFunction,
      sideEffectFunction,
      queryClient,
    })
  );

  if (!isSuccess) {
    return {data: null, isLoading, isError, isSuccess};
  }

  return {
    data: batched ? queries.map((elem) => elem.data) : data?.results || data,
    isLoading: false,
    isError: false,
    isSuccess: true,
    count: data?.count,
  };
}

//pathParamsArr é OBRIGATÓRIO e deve ser consistente com o pathParamsArr do GET
//Ex: GET =>  Comment => ["posts", 1, "comments", 1]
//    POST =>  Comment => ["posts", 1, "comments"]

//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, oldData, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no id retornado

// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function usePostRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback,
  postQueryKey
) {
  const queryClient = useQueryClient();
  if (!postQueryKey) postQueryKey = pathParamsArr;

  return useMutation(
    (requestData) => {
      return buildPostRequest(postQueryKey, requestData);
    },
    {
      onMutate: async (newData) => {
        const oldData = queryClient.getQueryData(pathParamsArr);
        if (startCallback) {
          await queryClient.cancelQueries({queryKey: postQueryKey});
          startCallback(queryClient, oldData, newData);
        }
        return {oldData};
      },
      onSuccess: (data) => {
        if (successCallback) successCallback(queryClient, data);
        else {
          queryClient.setQueryData([...pathParamsArr, data.id], data);
        }
      },
      onError: (error, newData, context) => {
        if (errorCallback)
          errorCallback(error, queryClient, context.oldData, newData);
        else {
          handleSubmitError(error, null);
        }
      },
    }
  );
}

//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no pathParamArr fornecido
// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function usePutRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback,
  putQueryKey
) {
  const queryClient = useQueryClient();
  if (!putQueryKey) putQueryKey = pathParamsArr;

  return useMutation(
    (requestData) => {
      return buildPutRequest(putQueryKey, requestData);
    },
    {
      onMutate: async (newData) => {
        const oldData = queryClient.getQueryData(pathParamsArr);
        if (startCallback) {
          await queryClient.cancelQueries({queryKey: putQueryKey});
          startCallback(queryClient, oldData, newData);
        }
        return {oldData};
      },
      onSuccess: (data) => {
        if (successCallback) successCallback(queryClient, data);
        else {
          queryClient.setQueryData(pathParamsArr, data);
        }
      },
      onError: (error, newData, context) => {
        if (errorCallback)
          errorCallback(error, queryClient, context.oldData, newData);
        else {
          handleSubmitError(error, null);
        }
      },
    }
  );
}
//successCallback, errorCallback, startCallback são OPCIONAIS

// Se não forem passadas, o comportamento padrão será executado
// Se forem passadas, o comportamento padrão NÃO SERÁ EXECUTADO

// A assinatura das funcoes e o comportamento padrão é:

// 1. successCallback:
// (queryClient, oldData, newData) => {}
//  Comportamento padrão: Atualiza o react-query do elemento com o novo dado baseado no pathParamArr fornecido
// 2. errorCallback:
// (error, queryClient, oldData, newData) => {}
//  Comportamento padrão: Notistack de erro

// 3. startCallback:
// (queryClient, oldData, newData) => {}
// Comportamento padrão: Nada
// Observação: Se o startCallback é fornecido, o queryClient.cancelQueries é chamado automaticamente

export function useDeleteRequest(
  pathParamsArr,
  successCallback,
  errorCallback,
  startCallback
) {
  const queryClient = useQueryClient();

  return useMutation(
    (requestData) => {
      return buildDeleteRequest(
        [...pathParamsArr, requestData.id],
        requestData
      );
    },
    {
      onMutate: async (newData) => {
        const oldData = queryClient.getQueryData(pathParamsArr);
        if (startCallback) {
          await queryClient.cancelQueries({queryKey: pathParamsArr});
          startCallback(queryClient, oldData, newData);
        }
        return {oldData};
      },
      onSuccess: (newData, oldData, context) => {
        if (successCallback) successCallback(queryClient, oldData, newData);
        else {
          queryClient.removeQueries({queryKey: [...pathParamsArr, oldData.id]});
        }
      },
      onError: (error, newData, context) => {
        if (errorCallback)
          errorCallback(error, queryClient, context.oldData, newData);
        else {
          handleSubmitError(error, null);
        }
      },
    }
  );
}

let batcherStorage = {};

export function buildBatchedGetRequest(pathParamsArr, queryParams) {
  const batcherStorageKey = pathParamsArr.slice(0, -1).join('/');
  let batcher = batcherStorage[batcherStorageKey];
  if (batcherStorage[batcherStorageKey] == undefined) {
    batcherStorage[batcherStorageKey] = create({
      fetcher: async (ids) => {
        const request = buildGetRequest(
          pathParamsArr.slice(0, -1),
          {
            id: ids,
            ...queryParams,
          },
          null
        );

        const result = await request();
        return result.results;
      },
      resolver: keyResolver('id'),
      scheduler: windowScheduler(70),
    });
    batcher = batcherStorage[batcherStorageKey];
  }

  const id = pathParamsArr.slice(-1);
  return async () => {
    return batcher.fetch(id);
  };
}

export function buildGetRequest(pathParamsArr, queryParamsArr, contextReq) {
  let params = {
    method: 'GET',
    withCredentials: true,
    url: '',
  };
  if (contextReq) {
    params.headers = {
      Cookie: getCookies(contextReq, params),
    };
  }
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  params.url = buildURL(baseUrl, pathParamsArr, queryParamsArr);
  return async function () {
    return axios(params).then((res) => {
      return res.data;
    });
  };
}

export function buildListRequest(pathParamsArr, queryParamsArr, contextReq) {
  let params = {
    method: 'GET',
    withCredentials: true,
    url: '',
  };
  if (contextReq) {
    params.headers = {
      Cookie: getCookies(contextReq, params),
    };
  }
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  params.url = buildURL(baseUrl, pathParamsArr, queryParamsArr);
  return async function () {
    return axios(params).then((res) => {
      return res.data;
    });
  };
}

export async function buildPostRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'POST',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };

  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  params.url = buildURL(baseUrl, pathParamsArr, []);

  return axios(params).then((res) => res.data);
}

export async function buildPutRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'PUT',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  params.url = buildURL(baseUrl, pathParamsArr, []);
  return axios(params).then((res) => res.data);
}

export async function buildDeleteRequest(pathParamsArr, bodyDict) {
  let params = {
    method: 'DELETE',
    withCredentials: true,
    url: '',
    data: bodyDict,
  };

  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  params.url = buildURL(baseUrl, pathParamsArr, []);

  return axios(params).then((res) => res.data);
}

export function getInfiniteScrollQueryKey(queryKey, queryParams) {
  return [...queryKey, {...queryParams, infiniteScroll: true, principal: true}];
}

export function useInfiniteScrollRequest(queryKey, queryParams, options) {
  const queryClient = useQueryClient();
  const {data, ...response} = useInfiniteQuery({
    queryKey: getInfiniteScrollQueryKey(queryKey, queryParams),
    queryFn: ({pageParam = 0}) => {
      return buildGetRequest(queryKey, {
        ...queryParams,
        offset: pageParam,
      })();
    },
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.next) {
        return;
      }

      return lastPage.limit + lastPage.offset;
    },
    ...options,
    // staleTime: Infinity,
  });

  const normalizedData = data?.pages.reduce(
    (acc, page) => [...acc, ...page.results],
    []
  );
  useQueries(
    createItemsQueries(queryKey, normalizedData, {
      queryClient,
      batched: true,
    })
  );

  return {
    ...response,
    data: normalizedData,
    count: data?.pages[data?.pages.length - 1].count,
  };
}

function createItemsQueries(
  paramsArr,
  data = [],
  {batched, queryTransformFunction, sideEffectFunction, queryClient}
) {
  return data?.map((elem) => {
    return {
      queryKey: [...paramsArr, elem.id],
      queryFn: batched && buildBatchedGetRequest([...paramsArr, elem.id], {}),
      initialData: elem,
      enabled: true,
      select: (data) => {
        if (queryTransformFunction) {
          return queryTransformFunction(data);
        }
        return data;
      },
      onSuccess: (data) => {
        if (sideEffectFunction) {
          const sideEffects = sideEffectFunction(data);
          sideEffects.forEach((sideEffect) => {
            queryClient.setQueryData(sideEffect.queryKey, sideEffect.data);
          });
        }
      },
    };
  });
}

function getCookies(contextReq) {
  const cookie = new Cookies(contextReq, null);
  const sessionCookie = cookie.get('sessionid');
  const escolaCookie = cookie.get('escola');
  return 'sessionid=' + sessionCookie + ';escola=' + escolaCookie + ';';
}

function buildURL(baseUrl, pathParamsArr, queryParamsArr) {
  let url = pathParamsArr.reduce((url, param) => {
    if (['string', 'number'].includes(typeof param)) {
      url += param + '/';
    }
    return url;
  }, baseUrl);

  Object.entries(queryParamsArr).forEach(([key, value], index) => {
    if (index == 0) url += '?';
    if (index != 0) url += '&';

    if (Array.isArray(value)) {
      if (value.length == 1) {
        url += key + '=' + value[0];
        return;
      } else if (value.length != 0) {
        url += key + '=' + value.join(',');
      }
    } else {
      url += key + '=' + value;
    }
  });

  return url;
}

//Função que pode ser usada ao invés de buildBatchedGetRequest e buildGetRequest para casos que se quer fazer batch de requisições do tipo:
// GET /posts?author=1
// GET /posts?author=2
// GET /posts?author=3
// Para /posts?author=1,2,3
// queryParamsConstant é o nome do parâmetro dentro do "queryParams" que é constante para todas as requisições. No exemplo acima, seria "author"
// Todos os outros parametros do queryParams deverão ser constantes para utilizar essa função

// resultKeyResolver é o nome do campo que será utilizado para filtrar os resultados. Por default, é o mesmo que o queryParamsConstant.
// Mas, em alguns casos que a resposta da requisição foge do padrao, pode ser necessário utilizar um campo diferente para filtrar os resultados. Exemplo:
// GET /posts?author=1,2,3
// {
//   results: [
//     {id: 1, author_id: 1},
//     {id: 2, author_id: 1},
//     {id: 3, author_id: 3},
//   ]
// }
// Nesse caso, o queryParamsConstant seria "author" e o resultKeyResolver seria "author_id"

export function buildBatchedGetRequestForArray(
  pathParamsArr,
  queryParams,
  queryParamsConstant,
  resultKeyResolver = false
) {
  if (!resultKeyResolver) resultKeyResolver = queryParamsConstant;

  let queryParamsWithoutConstant = {...queryParams};
  delete queryParamsWithoutConstant[queryParamsConstant];

  const batcherStorageKey =
    pathParamsArr.join('/') +
    JSON.stringify(
      queryParamsWithoutConstant,
      Object.keys(queryParamsWithoutConstant).sort()
    );
  let batcher = batcherStorage[batcherStorageKey];
  if (batcherStorage[batcherStorageKey] == undefined) {
    batcherStorage[batcherStorageKey] = create({
      fetcher: async (queryParamsArr) => {
        const queryParams = queryParamsArr.reduce((acc, curr) => {
          let currQueryParams = acc;
          Object.entries(curr).forEach(([key, value]) => {
            if (acc[key] == undefined) {
              currQueryParams[key] = value;
            } else if (!Array.isArray(acc[key])) {
              currQueryParams[key] = [acc[key], value];
            } else {
              currQueryParams[key] = [...acc[key], value];
            }
          });
          return currQueryParams;
        }, {});
        const request = buildGetRequest(pathParamsArr, queryParams, null);

        const result = await request();
        return result.results;
      },
      resolver: (results, query) => {
        return results.filter((result) => {
          if (Array.isArray(result[resultKeyResolver]))
            return result[resultKeyResolver].includes(
              query[queryParamsConstant]
            );
          if (Array.isArray(query[queryParamsConstant]))
            return query[queryParamsConstant].includes(
              result[resultKeyResolver]
            );

          return result[resultKeyResolver] == query[queryParamsConstant];
        });
      },
      scheduler: windowScheduler(70),
    });
    batcher = batcherStorage[batcherStorageKey];
  }

  return async () => {
    return batcher.fetch(queryParams);
  };
}
